.viewerTab {
  position: relative;
  height: 100%;
}

.viewerFull {
  width: 100%;
  height: auto;
  position: relative;
}

.viewerThree {
  height: 100%;

  canvas {
    height: 100% !important;
    object-fit: cover;
  }
}

.viewerImage {
  height: calc(65vh - 10rem);
  max-width: var(--max-width);
  margin: 0 auto;
}

.viewerVideo {
  width: 100%;
  height: -webkit-fill-available;
  height: 100vh;
  position: relative;

  .videoFullPlayer {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .viewerPlay {
    position: absolute;
    background: transparent;
    border: none;
    outline: none;
    color: var(--white);
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 10;

    svg {
      width: 8rem;
      height: 8rem;
    }
  }
}

.tabs {
  flex: 1;
  background: var(--black);
  position: relative;

  &.tabsFull {
    min-height: 100%;
    padding-bottom: 12rem;

    & [data-reach-tab-list] {
      position: fixed;
      z-index: 10;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
}

.tabHead {
  height: 65vh;
  width: 100%;
  position: sticky;
  top: -30vh;
  z-index: 3;
  max-width: var(--max-width);
  margin: 0 auto;
  .tabImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.tabNav {
  text-align: center;
  padding: 0 2rem;
  max-width: var(--max-width);
  margin: 0 auto;

  &.lastTab {
    position: fixed;
    bottom: 12rem;
    left: 0;
    right: 0;
    z-index: 1;
  }

  button + button {
    margin-top: 3rem;
  }
}

.contentView {
  background: var(--black);
  padding: 2rem;
  max-width: var(--max-width);
  margin: 0 auto;

  h3 {
    margin: 0 0 1rem;
    font-size: 3rem;
    font-weight: bold;
    line-height: normal;
    color: var(--white);

    strong {
      color: var(--pink);
    }
    em {
      color: var(--pale-blue);
    }
  }

  p {
    margin: 0 0 3rem;
    font-size: 2rem;
    line-height: 1.5;
    color: var(--white);
  }
}

.videoBloc {
  height: 15rem;
  margin: 3rem 0 2rem;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 3rem 2rem;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background-color: var(--black);
    border-radius: 1rem;
    z-index: 2;
    top: 0;
    bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--white);
    position: relative;
    z-index: 3;
  }

  span {
    margin: 4px 0 0;
    opacity: 0.75;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--white);
    position: relative;
    z-index: 3;
  }
}

.videoPlayer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  border-radius: 1rem;
  overflow: hidden;
  object-fit: cover;
}

.fullscreen {
  position: absolute;
  z-index: 11;
  outline: none;
  border: none;
  padding: 0;
  background: var(--black-25);
  color: var(--white);
  top: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.playBtn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--white);
  z-index: 10;
  width: 4rem;
  height: 4rem;
  border: 2px solid var(--white);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.cardBottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2rem 2rem 0 0;
  padding: 2rem;
  background: var(--white);
  z-index: 10;
  width: 100%;
  max-width: var(--max-width);
  box-shadow: 0 -24px 48px 0 rgba(2, 16, 22, 0.5);
}

.tabImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tabContent {
  margin: 2rem auto -10rem;
  padding: 0 2rem 10rem;
  font-size: 14px;
  line-height: 1.5;
  color: var(--white);
  max-width: var(--max-width);
}

.bottom {
  text-align: center;
  margin: 2rem 0 4rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}
