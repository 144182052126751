.overlay {
  padding: 0 0 48px;
  /* background-color: rgba(2, 16, 22, 0.25); */
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
}
