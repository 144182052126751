.container {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;

  :global(.isLastScene) & {
    bottom: 14rem;
  }
}

.card {
  width: 280px;
  padding: 2rem 2rem 3rem 11rem;
  border-radius: 1.5rem;
  background: var(--white);
  position: relative;
  box-sizing: border-box;
  z-index: 2;

  &.open {
    width: 360px;
    max-width: 96%;
    padding: 0px;
    margin-bottom: -4rem;
    border-radius: 1.5rem 1.5rem 0 0;
  }
}

.figure {
  position: absolute;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  left: 1rem;
  top: -2rem;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;

  .open & {
    position: relative;
    width: 100%;
    height: 18rem;
    border-radius: 1.5rem 1.5rem 0 0;
    left: auto;
    top: auto;

    &:before {
      content: "";
      position: absolute;
      opacity: 0.25;
      background-color: var(--black);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }

  .open & img {
    border-radius: 1.5rem 1.5rem 0 0;
  }
}

.icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border: 2px solid var(--white);
  bottom: -0.5rem;
  right: -1rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--green);
  color: var(--white);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .open & {
    right: 3rem;
    width: 6rem;
    height: 6rem;
    border: transparent;
    bottom: -1.5rem;
    z-index: 3;
  }

  svg {
    width: 70%;
    height: auto;
  }
}

.content {
  display: flex;
  flex-direction: column;

  .open & {
    padding: 2rem 3rem;
  }
}

.goalInfo {
  position: absolute;
  bottom: 0;
  padding: 2rem 3rem;
  z-index: 3;
  display: block;
  text-align: left;

  h4 {
    opacity: 0.75;
    font-size: 12px;
    font-weight: bold;
    color: var(--white);
  }

  h3 {
    margin: 0;
    font-size: 2.25rem;
    font-weight: bold;
    color: var(--white);
    padding-right: 6rem;
  }
}

.title {
  margin: 0 0 0.5rem;
  font-family: Helvetica;
  font-size: 2rem;
  font-weight: bold;
  color: var(--dark-blue);
}

.desc {
  font-family: Helvetica;
  font-size: 1.5rem;
  color: var(--soft-grey);
}

.text {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.5;
  color: var(--dark-blue);
  margin: 0 0.5rem 2rem;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center > * ~ * {
  margin-top: 0.5rem;
}

.goalIcon {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow: hidden;
  position: relative;
  background: var(--black);

  svg {
    width: 4rem;
    height: 4rem;
  }
}

.cardWithHotspot {
  display: flex;
  flex-direction: column;
  align-items: center;

  .card {
    margin-top: 1rem;
  }
}

// Card Action

.iconAction {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  svg {
    width: 4rem;
    height: 4rem;
  }
}

.close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--white);
  z-index: 3;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;

  svg {
    width: 18px;
    height: 18px;
  }
}

.cardOverlayOpen {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: all;
}
