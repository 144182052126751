.drawer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--black);
  z-index: 10;
  height: 100%;
  overflow-y: scroll;
}
