:global(.goalCardIsOpen) .edge {
  display: none;
}

.edgeContainer {
  position: absolute;
  z-index: 10000;
}

.edge {
  background: var(--green);
  border: none;
  color: var(--white);
  display: inline-flex;
  width: 6rem;
  height: 6rem;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: 3rem 3rem 3rem 0.5rem;
  margin: 2rem;
  outline: none;
  /* animation-duration: 1.2s;
  animation-name: bounce;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0); */
}

.pos-l {
  transform: translateY(-50%) rotate(0deg);
}

.pos-r {
  transform: translateY(-50%) rotate(180deg);
}

.pos-t {
  transform: translateX(-50%) rotate(90deg);
}

.pos-b {
  transform: translateX(-50%) rotate(-90deg);
}

.pos-tr {
  transform: rotate(135deg);
}

.pos-tl {
  transform: rotate(40deg);
}

.pos-bl {
  transform: rotate(-45deg);
}

.pos-br {
  transform: rotate(-135deg);
}

.edgeContent {
  transform: rotate(135deg);
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateX(0) rotate(45deg);
  }
  10% {
    transform: scale(1.1, 0.9) translateX(0) rotate(45deg);
  }
  30% {
    transform: scale(0.9, 1.1) translateX(-1rem) rotate(45deg);
  }
  50% {
    transform: scale(1.05, 0.95) translateX(0) rotate(45deg);
  }
  57% {
    transform: scale(1, 1) translateX(-0.5rem) rotate(45deg);
  }
  64% {
    transform: scale(1, 1) translateX(0) rotate(45deg);
  }
  100% {
    transform: scale(1, 1) translateX(0) rotate(45deg);
  }
}
