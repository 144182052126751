.fullHeight {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  background-color: rgba(2, 16, 22, 0.5);
}

.scanId {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  max-height: 1000px;
  max-width: var(--max-width);
  transform: translate3d(-50%, -50%, 0);
  background-size: auto 150%;
  background-position: center center;

  @media (min-width: 640px) {
    border-radius: 3rem;
    overflow: hidden;
  }
}

.overlay {
  background: var(--black);
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 3;
  padding: 12rem 0 6rem;
}

.contentCard {
  font-size: 18px;
  line-height: 1.2;
  color: var(--white);
  font-weight: bold;
  margin-bottom: 12rem;
  padding: 0 4rem;
  p + p {
    margin-top: 1rem;
  }
  strong {
    line-height: 1;
    font-size: 5rem;
    font-family: "Oswald", sans-serif;
  }
}

.cardBottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div + button {
    margin-top: 3rem;
  }
}

.text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: var(--white);
  width: 280px;
  margin: 0 auto;
  line-height: 1.6;
}
