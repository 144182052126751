.scanId {
  width: 340px;
  height: 514px;
  border-radius: 3rem;
  background-color: var(--white);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4rem;
  overflow: hidden;
  padding-bottom: 4rem;
  background-size: auto 150%;
  background-position: center center;

  @media (max-height: 740px) {
    height: 360px;
  }

  @media (max-width: 374px) {
    width: 280px;
  }
}

.text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: var(--white);
  width: 280px;
  margin: 0 auto;
  line-height: 1.6;
}
