.navbar {
  position: relative;
}

.navbar:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 12rem;
  opacity: 0.2;
  background-image: linear-gradient(
    to bottom,
    #010c11,
    rgba(1, 12, 17, 0) 100%
  );
}

.navbarContent {
  display: flex;
  padding: 1rem;
  align-items: center;
  color: var(--white);
  position: relative;
  z-index: 2;
  position: relative;
}

.navLeft,
.navRight {
  outline: none;
  background: transparent;
  border: none;
  color: inherit;
}

.navLeft {
  width: 5rem;
  height: 5rem;
}

.navRight {
  width: 5rem;
  height: 5rem;
}

.navTitle {
  margin: 0 auto;
  text-align: center;
  padding: 0 2rem;
  font-size: 2rem;
  font-weight: bold;
}

.navTitleBtn {
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
  }
}

// Dropdown
.dropdown {
  position: absolute;
  top: 100%;
  background: var(--white);
  width: 300px;
  left: 50%;
  margin-left: -150px;
  margin-top: 2rem;
  border-radius: 1rem;

  @media (min-width: 480px) {
    width: 360px;
    margin-left: -180px;
  }

  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 2rem;
    border-left-width: 4rem;
    border-right-width: 4rem;
    margin-left: -4rem;
  }
}

.dropdownScrollArea {
  height: 100%;
  overflow-y: scroll;
  max-height: 344px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.dropdownThumb {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;

  img {
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    .locked & {
      opacity: 0.75;
    }
  }
}

.dropdowIcon {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  background: var(--green);
  color: var(--white);
  position: absolute;
  right: -1rem;
  bottom: -0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;

  .locked & {
    background: var(--black-pure);
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.dropdownTitle {
  display: flex;
  margin-left: 2rem;
  margin-right: 1rem;
  color: var(--black);
  text-align: left;
  flex: 1;
  align-items: center;

  .locked & {
    opacity: 0.25;
  }
}

.locked {
}
