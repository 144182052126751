.modal {
  position: fixed;
  z-index: 100;
  background: var(--white);
  width: calc(100% - 2rem);
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 1.5rem;
  overflow: hidden;
  max-width: 400px;
}

.close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--white);
  z-index: 3;

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.header {
  height: 144px;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.headerContent {
  display: flex;
  color: var(--white);
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem 3rem;
  position: relative;
  z-index: 2;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);

  h3 {
    margin-top: 0.5rem;
    font-size: 18px;
    font-weight: bold;
    color: var(--white);
    padding-right: 6rem;
  }
}
.type {
  opacity: 0.75;
  font-size: 12px;
  font-weight: bold;
  color: var(--white);
}

.content {
  padding: 2rem 3rem;
  color: var(--black);

  p {
    font-size: 14px;
    line-height: 1.5;
    color: var(--dark-blue);
    margin-bottom: 2rem;
    padding-right: 8rem;
  }
}

.icon {
  color: var(--white);
  background: var(--green);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  position: absolute;
  bottom: -2rem;
  right: 3rem;
  z-index: 5;
  svg {
    width: 4rem;
    height: 4rem;
  }
}
