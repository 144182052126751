.modal,
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay {
  z-index: 10;
  backdrop-filter: blur(16px);
  background-color: rgba(2, 16, 22, 0.5);
}

.modal {
  z-index: 12;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 7rem 2rem 4rem;

  @media (min-height: 930px) {
    justify-content: flex-end;
  }
}

.centerV {
  @media (min-height: 930px) {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -257px;
    margin-left: -170px;
  }
}
