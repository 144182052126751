.hotspot {
  background: #fff;
  color: var(--green);
  width: 6rem;
  height: 6rem;
  margin: 2rem;
  position: relative;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   border-radius: 50%;
  //   width: 8rem;
  //   height: 8rem;
  //   top: 50%;
  //   transform: translate3d(-50%, -50%, 0);
  //   left: 50%;
  //   opacity: 0.75;
  //   background-color: var(--green);
  //   z-index: -1;
  // }

  svg {
    width: 4rem;
    height: 4rem;
    position: relative;
    z-index: 10;
  }
}
