[data-reach-tab-list] {
  background: var(--black-pure);
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 768px) {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

/* styles all tabs */
[data-reach-tab] {
  padding: 1.25rem 2rem;
  min-height: 5rem;
  border: none;
  border-radius: 1rem;
  background: transparent;
  color: var(--white);
  font-size: 2rem;
  font-weight: bold;
  transition: color 0.15s linear, background 0.15s linear;
  outline: none;
  margin: 0 0.5rem;
  flex-shrink: 0;
  text-transform: normal;
}
/* styles only the selected tab */
[data-reach-tab][data-selected] {
  background: var(--white);
  color: var(--black);
}
