:root {
  --base: 8px;
  --black-pure: #000000;
  --black: #021016;
  --black-25: rgba(0, 0, 0, 0.25);
  --black-50: rgba(0, 0, 0, 0.5);
  --dark-grey: #333333;
  --green-50: rgba(125, 184, 14, 0.5);
  --green-25: rgba(125, 184, 14, 0.25);
  --soft-grey: #a7afb9;
  --dark-blue: #092241;
  --red: #f53737;
  --system-hit: rgba(0, 255, 255, 0.25);
  --green: #7db80e;
  --white: #ffffff;
  --white-50: rgba(255, 255, 255, 0.5);
  --white-25: rgba(255, 255, 255, 0.25);
  --white-33: rgba(255, 255, 255, 0.33);
  --blue: #3cb1ec;
  --ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
  --pink: #d6b8e0;
  --pale-blue: #abddf7;
  --max-width: 640px;
}

/* TODO: Remove when reactify */
.hidden {
  display: none;
}

body {
  margin: 0;
  font-family: Helvetica, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.text-center {
  text-align: center;
}
