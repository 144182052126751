.overlay {
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  background-color: rgba(2, 16, 22, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 12rem;
  height: 100%;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  svg + svg {
    margin-top: 6rem;
  }

  button {
    margin-top: 16rem;
  }
}
