.sensor {
  display: inline-flex;
  align-items: stretch;
  border-radius: 1rem;
  overflow: hidden;
}

.sensor-icon {
  color: var(--white);
  background-color: var(--black);
  padding: 1rem;
  transition: background-color 0.2s linear;
}

.sensor-icon.red {
  background-color: var(--red);
}
.sensor-icon.blue {
  background-color: var(--blue);
}

.level-ok .sensor-icon {
  background-color: var(--blue);
}

.sensor-reading {
  background: var(--white);
  padding: 1rem;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: var(--dark-blue);
}

.sensorContainer {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
