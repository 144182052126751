.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 480px;
  margin: 0 auto;
  padding: 7rem 3rem;
  min-height: 100%;
}

.header {
  text-align: center;

  h2 {
    margin: 0 0 6rem;
    font-size: 3rem;
    font-weight: bold;
    color: var(--white);
    line-height: normal;
  }
}

.iconHead {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  border: solid 8px var(--white);
  border-radius: 50%;
  color: var(--green);
  margin-bottom: 3rem;
}

.center {
  margin-top: auto;
}

.supTitle {
  font-family: "Oswald", sans-serif;
  font-size: 3rem;
  font-weight: bold;
  color: var(--green);
  text-align: left;
  margin-bottom: 2rem;
}

.title {
  margin: 0 0 2rem;
  font-family: "Oswald", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  line-height: 1;
  color: var(--white);
}

.text {
  opacity: 0.9;
  font-size: 18px;
  line-height: 1.33;
  color: var(--white);

  strong {
    font-weight: bold;
  }
}

.infoText {
  opacity: 0.9;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: var(--white);
  padding: 1rem 3rem;
  border-radius: 1rem;
  border: solid 1px rgba(255, 255, 255, 0.25);
  margin: 4rem 0;
}

.bottom {
  margin-top: auto;
  text-align: center;
}

.nav {
  display: inline-grid;
  margin: 4rem 0 0;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 6px;
}

.dot {
  width: 1.5rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: var(--soft-grey);
  transform: scaleX(0.66667);
}

.dotActive {
  transform: scaleX(1);
  background: var(--white);
}

.logos {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  align-items: center;
  justify-content: center;

  @media (max-height: 730px) {
    margin-top: 8rem;
  }

  svg {
    height: auto;
    width: 15rem;
    opacity: 0.9;

    @media (max-height: 730px) {
      width: 12rem;
    }
  }

  a + div {
    margin-top: 2rem;

    svg {
      width: 9rem;
      @media (max-height: 730px) {
        width: 12rem;
      }
    }

    img {
      margin-left: 2rem;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }
}

.partners {
  margin-top: 4rem;
  margin-bottom: 2rem;
  padding: 1.5rem 3rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;

  p {
    font-size: 12px;
    text-align: center;
  }
}

.partnersLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  img + img {
    margin-left: 2rem;
  }
}

.sharing {
  text-align: center;

  h3 {
    margin: 0 0 1rem;
    font-size: 3rem;
    font-weight: bold;
    color: var(--white);
  }

  > p {
    opacity: 0.9;
    font-size: 14px;
    color: var(--white);
  }
}

.sharingGrid {
  display: grid;
  grid-template-columns: repeat(2, 12rem);
  gap: 1rem;
  margin: 6rem auto 0rem;
  justify-content: center;

  a {
    width: 12rem;
    height: 12rem;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 6rem;
    height: 6rem;
  }
}

.cardBottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2rem 2rem 0 0;
  padding: 2rem;
  background: var(--white);
  z-index: 10;
  width: 100%;
  max-width: var(--max-width);
  box-shadow: 0 -24px 48px 0 rgba(2, 16, 22, 0.5);
}
