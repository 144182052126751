.buttonPrimary {
  padding: 15px 16px 14px;
  border-radius: 1.5rem;
  background-color: var(--green);
  outline: none;
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  border: none;
}

.full {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.onboard {
  flex: inherit;
  margin-top: 6rem;
  padding: 21px 16px;
  font-size: 18px;
  border-radius: 2rem;

  @media (max-height: 730px) {
    margin-top: 2rem;
    padding: 1.5rem 1rem;
    font-size: 2rem;
    line-height: 1;
  }
}

.small {
  font-size: 1.5rem;
  padding: 9px 8px;
  border-radius: 1rem;
}

.buttonText {
  padding: 15px 16px 14px;
  font-size: 2rem;
  outline: none;
  border: none;
  background: transparent;
  font-weight: bold;
  color: var(--dark-blue);
}

.buttonOutline {
  outline: none;
  padding: 15px 16px 14px;
  border-radius: 2rem;
  background-color: transparent;
  font-size: 2rem;
  font-weight: bold;
  color: var(--white);
  border: 2px solid var(--white);
}

.modalButton {
  border: solid 2px var(--white);
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: transparent;
  color: var(--white);
  outline: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
