.splash {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(16px);
  background-color: rgba(2, 16, 22, 0.5);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 4rem;
  max-width: 600px;
  margin: 2rem auto;
}

.title {
  padding: 3vh 0 2rem;
  font-size: 24px;
  font-family: "Oswald", helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  color: var(--white);
  margin-top: auto;

  @media (max-height: 730px) {
    font-size: 2.5rem;
  }

  strong {
    color: var(--green);
    font-size: 48px;
    @media (max-height: 730px) {
      font-size: 4rem;
    }
  }
}

.text {
  opacity: 0.9;
  font-size: 14px;
  text-align: center;
  color: var(--white);
  line-height: 1.4;

  @media (max-height: 730px) {
    font-size: 13px;
  }

  p + p {
    margin-top: 1em;
  }
}

.logos {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;

  @media (max-height: 730px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  svg {
    height: auto;
    width: 22rem;
    opacity: 0.9;

    @media (max-height: 730px) {
      width: 10rem;
    }
  }

  a + a {
    margin-top: 3rem;
    @media (max-height: 730px) {
      margin-top: 0;
    }
    svg {
      width: 16rem;
      @media (max-height: 730px) {
        width: 10rem;
      }
    }

    @media (max-height: 730px) {
      img {
        width: 12rem;
        height: auto;
      }
    }
  }
}

// onboard
.onboardTitle {
  margin: auto 0 1rem;
  font-size: 24px;
  font-weight: bold;
  color: var(--white);
}

.card {
  width: 100%;
  margin: 4rem 0;
  padding: 4rem 2rem 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 3rem 6rem 0 rgba(2, 16, 22, 0.5);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardTitle {
  margin: 3rem 2rem 1rem;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: var(--black);
}

.cardTitle + .cardText {
  margin-top: 0rem;
}

.cardText {
  margin: 3rem 1rem 2rem;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: var(--black);
}

.bottomAction {
  margin-top: auto;
  margin-bottom: 9rem;
}

.bigCheck {
  width: 8rem;
  height: 8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  border-radius: 50%;
  color: var(--white);

  svg {
    width: 6rem;
    height: 6rem;
  }
}

.cardSteps {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 6px;
}

.dot {
  width: 1.5rem;
  height: 1rem;
  border-radius: 0.5rem;
  background-color: var(--soft-grey);
  transform: scaleX(0.66667);
}

.dotActive {
  transform: scaleX(1);
  background: var(--green);
}
