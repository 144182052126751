.progress-container {
  position: relative;
  width: 12rem;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-circle {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.progress-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
}

.progress {
  animation: progress 2s 1 cubic-bezier(0.33, 1, 0.68, 1) forwards;
}

@keyframes progress {
  0% {
    stroke-dashoffset: 251.327;
  }

  100% {
    stroke-dashoffset: 0;
  }
}
