.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(16px);
  background-color: rgba(2, 16, 22, 0.5);
  overflow-y: scroll;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  margin: 0 auto;
  padding: 9rem 3rem;
  min-height: 100%;
}

.center {
  margin: auto 0;
}

.icon {
  color: var(--green);
  margin-bottom: 1.5rem;

  svg {
    width: 12rem;
    height: 12rem;
  }
}

.title {
  margin-bottom: 2.5rem;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.25;
  color: var(--white);
}

.text {
  font-size: 2rem;
  line-height: 1.5;
  color: var(--white);
  margin-bottom: 2rem;
}
