:global(.goalCardIsOpen) {
  .mainHotspot,
  .linkHotspot {
    display: none;
  }
}

.mainHotspot {
  outline: none;
  width: 6rem;
  height: 6rem;
  background-color: var(--white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--green);
  border: none;
  position: absolute;
  box-shadow: 0 0 0 1rem var(--green);
  left: -3rem;
  top: -3rem;
  z-index: 4;

  svg {
    width: 4rem;
    height: 4rem;
  }
}

.linkHotspot {
  outline: none;
  width: 6rem;
  height: 6rem;
  background-color: var(--white-33);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--white);
  border: none;
  transform: translate3d(-3rem, -3rem, 0);
  position: relative;

  svg {
    width: 4rem;
    height: 4rem;
  }
}

.lockedIcon,
.checkedIcon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--white);
  color: var(--white);
  background-color: var(--black);
  border-radius: 50%;
  bottom: -1rem;
  right: -1rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.checkedIcon {
  background: var(--green);
}

.tooltip,
.tooltipLarge {
  width: 146px;
  padding: 0rem 3rem 3.5rem 5rem;
  position: absolute;
  transform: translate3d(-2rem, 2rem, 0px);
  bottom: 100%;
  left: 0px;
}

.tooltipLarge {
  width: 320px;
}

.tooltipContent {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  text-align: right;
  color: var(--white);

  .tooltipLarge & {
    text-align: left;
  }
}

.tooltipRound {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: solid 4px var(--white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  left: 0px;
  bottom: 0px;

  span {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: var(--white);
  }
}

.tooltipLine {
  position: absolute;
  height: 4px;
  background: var(--white);
  width: calc(100% - 5rem);
  bottom: calc(2rem - 2px);
  left: 4rem;
}

.tooltipTip {
  position: absolute;
  height: 4px;
  background: var(--white);
  width: 2.5rem;
  transform: rotate(-45deg);
  right: -1.5rem;
  bottom: calc(2rem - 2px);
  transform-origin: bottom left;
}
