.interface {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  background-color: transparent;
  pointer-events: none;
}

.interface * {
  pointer-events: all;
}

.viewer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;

  :global(#pano) > div:first-of-type {
    z-index: 3;
  }
}

.overlay {
  background-color: rgba(2, 16, 22, 0.25);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;

  :global(.goalCardIsOpen) & {
    background-color: rgba(2, 16, 22, 0.5);
  }
}

:global(.goalCardIsOpen) .viewer {
  pointer-events: none;
}
